<template>
	<div>
		<a-spin :spinning="loading">
		<a-form ref="form" name="form" :model="modelRef" @finish="onSubmit" :labelCol="{ span: 6 }">
			<a-form-item label="商品返佣计算节点" name="calcNode">
				<a-radio-group v-model:value="modelRef.calcNode">
					<a-radio :value="1">商品利润</a-radio>
					<a-radio :value="2">订单实付金额</a-radio>
				</a-radio-group>
			</a-form-item>
			
			<a-form-item label="卖品返佣计算节点" name="snackCalcNode">
				<a-radio-group v-model:value="modelRef.snackCalcNode">
					<!-- <a-radio :value="1">卖品利润</a-radio> -->
					<a-radio :value="2">订单实付金额</a-radio>
				</a-radio-group>
			</a-form-item>
			
			<a-form-item label="现金购票返佣计算节点" name="ticketCalcNode">
				<a-radio-group v-model:value="modelRef.ticketCalcNode">
					<a-radio :value="1">支付金额</a-radio>
				</a-radio-group>
				<span style="color: red;">实付金额 = 订单总额 - 补差，即影票补差和退款服务费不分销 </span>
			</a-form-item>
			
			<a-row>
				<a-col :offset="4">
					<div style="color: red;">注：个人返佣 = 返佣计算节点 ✖ 分销商等级比例</div>
					
					<div style="margin-top: 20px;margin-left: 40px;">
						<a-button v-permission="['member_distributor_setting_submit']" type="primary" html-type="submit">提交</a-button>
					</div>
				</a-col>
			</a-row>
		</a-form>
		</a-spin>
	</div>
</template>

<script>
	import { getDistributionSettingDetail, updateDistributionSetting } from '@/service/modules/distribution.js';
	export default {
		data() {
			return {
				loading: false,
				modelRef: {
					calcNode: 1,
					snackCalcNode: 2,
					ticketCalcNode: 2
				}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async getData() {
				this.loading = true;
				try {
					let ret = await getDistributionSettingDetail({});
					this.loading = false;
					if (ret.code === 200) {
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				this.loading = true;
				try {
					let ret = await updateDistributionSetting(postData);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('修改成功');
					}
				} catch(e) {
					this.loading = false;
				}
			}
		}
	}
</script>

<style>
</style>
